import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

const PostsList = ({ posts }) => {
  return (
    <section className="flex flex-wrap">
      {posts.map(({ node: post }) => {
        let schema = {
          '@context': 'https://schema.org',
          '@type': 'Review',
          '@id': `https://dmautoleasing.com/blog${post.uri}`,
          itemReviewed: {
            '@type': 'LocalBusiness',
            image: {
              '@type': 'ImageObject',
              url: post.featuredImage?.node.mediaItemUrl,
              width: post.featuredImage?.node.mediaDetails.width,
              height: post.featuredImage?.node.mediaDetails.height,
            },
          },
          name: 'D&amp;M Auto Leasing',
          author: {
            '@type': 'Person',
            name: post.author.node.name,
          },
          image: [
            {
              image: {
                '@type': 'ImageObject',
                url: post.featuredImage?.node.mediaItemUrl,
                width: post.featuredImage?.node.mediaDetails.width,
                height: post.featuredImage?.node.mediaDetails.height,
              },
            },
          ],
        }

        return (
          <div key={post.id} className="w-full md:w-1/2">
            {post.showReview.showReviewSchema && (
              <Helmet>
                <script type="application/ld+json">
                  {`${JSON.stringify(schema)}`}
                </script>
              </Helmet>
            )}
            <div
              style={{
                backgroundImage: `url(${post.featuredImage?.node.mediaItemUrl})`,
                height: '400px',
              }}
              className="bg-cover bg-center relative bg-gray-300 "
            >
              <div className="h-5 absolute bottom-5 bg-brand-blue opacity-40 w-full" />
              <div className="h-5 absolute bottom-0 bg-brand-blue opacity-80 w-full" />
            </div>
            <div className="bg-brand-blue w-full p-6 h-40 flex items-center justify-center">
              <Link to={`/blog/${post.slug}/`}>
                <h3 className="text-center font-light uppercase text-white">
                  {post.title}
                </h3>
              </Link>
            </div>

            <div className="p-8 md:p-20">
              <div
                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                className="text-lg blog-text"
              />
              <div className="mt-10">
                <Link
                  className="button is-small rounded-full text-lg uppercase border border-black py-4 px-8 hover:border-brand-green transition-all"
                  to={`/blog/${post.slug}/`}
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default PostsList
