import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PageTitle from '../components/Blocks/PageTitle'
import PostsList from '../components/PostList'
import { CaretLeft, CaretRight } from 'phosphor-react'

const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const LeaseVsBuyTemplate = ({ title, content, posts }) => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState(posts.edges || [])
  const [perPage] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const blogRef = createRef()

  useEffect(() => {
    let isCanceled = false
    if (!isCanceled && posts.edges.length)
      setPageCount(Math.ceil(posts.edges.length / perPage))

    return () => {
      isCanceled = true
    }
  }, posts)

  const handleNextClick = e => {
    e.preventDefault()
    const nextPage = page + 1

    if (nextPage <= pageCount) {
      blogRef.current?.scrollIntoView({
        behavior: 'smooth',
        top: -100,
      })
      setPage(nextPage)
    }
  }

  const handlePrevClick = e => {
    e.preventDefault()
    const prevPage = page - 1

    if (prevPage >= 1) {
      blogRef.current?.scrollIntoView({
        behavior: 'smooth',
        top: -100,
      })
      setPage(prevPage)
    }
  }

  return (
    <div className="w-full h-full">
      <PageTitle title={title} />

      <div
        className="transition-all"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div ref={blogRef} className="pb-10" id="#blog-start" />
      <PostsList posts={paginate(data, perPage, page)} />
      <nav
        className="pagination px-8 md:px-20 pb-10 max-w-screen-xl mx-auto text-center flex items-center justify-center"
        role="navigation"
      >
        <div className="navbar navbar-menu flex gap-x-4">
          {page !== 1 && (
            <div className="navbar-item">
              <span
                rel="prev"
                className="flex items-center cursor-pointer"
                onClick={handlePrevClick}
              >
                <CaretLeft /> Prev
              </span>
            </div>
          )}
          {page !== pageCount && (
            <div className="navbar-item">
              <span
                rel="next"
                className="flex items-center justify-center cursor-pointer"
                onClick={handleNextClick}
              >
                Next <CaretRight />
              </span>
            </div>
          )}
        </div>
      </nav>
    </div>
  )
}

const Page = ({ data, pageContext }) => {
  const { wpPage: page, allWpPost: posts } = data

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <LeaseVsBuyTemplate
        page={page}
        title={page.title}
        content={page.content}
        faq={page.faq}
        posts={posts}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query LeaseVsBuy($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "lease-vs-buy" } } } }
      }
    ) {
      edges {
        node {
          id
          showReview {
            showReviewSchema
          }
          title
          slug
          content
          excerpt
          uri
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`
