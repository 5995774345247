import React from 'react'

const PageTitle = ({ title, children }) => (
  <div className="bg-gray-300 py-10 text-center px-8">
    <h1 className="font-bold text-brand-blue mb-0 uppercase tracking-widest">
      {title}
    </h1>
    {children}
  </div>
)

export default PageTitle
